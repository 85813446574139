/* eslint-disable class-methods-use-this */
import useJwt from '@/auth/jwt/useJwt'
import LocalStorageService from '@/services/localStorageService'

export default class EmailService {
  sendMessage(formData) {
    return useJwt.post('/email/send', null, formData)
  }

  getUrlDownloadAttachment(name, file) {
    return useJwt.download(`/email/templates/attachment/${name}/${file}`)
  }

  listTemplates() {
    return useJwt.get('/email/templates')
  }

  postTemplate(data) {
    LocalStorageService.remove(LocalStorageService.emailTemplates)
    return useJwt.post('/email/template', {}, data)
  }

  updateTemplate(id, data) {
    LocalStorageService.remove(LocalStorageService.emailTemplates)
    return useJwt.post(`/email/template/${id}`, {}, data)
  }

  deleteTemplate(id) {
    LocalStorageService.remove(LocalStorageService.emailTemplates)
    return useJwt.delete(`/email/template/${id}`)
  }

  getTemplate(id) {
    return useJwt.get(`/email/template/${id}`)
  }

  listTypes() {
    return useJwt.get('/email/template-types')
  }

  listVariables(userId) {
    return useJwt.get(`/email/template-variables/${userId}`)
  }

  getEmail(id) {
    return useJwt.get(`/email/${id}`)
  }

  listAndStoreTemplates() {
    if (LocalStorageService.getArray(LocalStorageService.emailTemplates) !== null) {
      return new Promise(resolve => {
        resolve(LocalStorageService.getArray(LocalStorageService.emailTemplates))
      })
    }

    return this.listTemplates().then(response => {
      if (response.data !== null) {
        let { templates } = response.data.data
        templates = Object.values(templates).sort((a, b) => a.shortcut.localeCompare(b.shortcut))
        LocalStorageService.setArray(LocalStorageService.emailTemplates, templates)
        return templates
      }

      return []
    })
  }

  getInitialConfig(accountManagerId, userId) {
    return useJwt.get(`/email/initial-config/${accountManagerId}/${userId}`)
  }

  static listSentEmail(data, page = 0) {
    return useJwt.get('/email/sent', {
      page,
      ...data,
    })
  }

  static listDisabledEmails(data, page = 0) {
    return useJwt.get('/email/disabled', {
      page,
      ...data,
    })
  }

  static enableDisabled(id) {
    return useJwt.post(`/email/enable/${id}`)
  }

  static disable({ email }) {
    return useJwt.post('/email/disable', {}, { email })
  }
}
