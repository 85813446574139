<template>
  <section id="disabled-emails">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="Data de bloqueio"
                label-for="created-at"
              >
                <flat-pickr
                  id="created-at"
                  ref="datePickerCreatedAt"
                  v-model="filtersValues.createdAt"
                  class="form-control"
                  :config="pickrConfig"
                />
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Data de liberação"
                label-for="disable-until"
              >
                <flat-pickr
                  id="disable-until"
                  ref="datePickerDisableUntil"
                  v-model="filtersValues.disableUntil"
                  class="form-control"
                  :config="pickrConfig"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="filtersValues.email"
                  :disabled="isLoading"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Motivo"
                label-for="reason"
              >
                <b-form-select
                  id="reason"
                  v-model="filtersValues.reason"
                  :options="disabledEmailReasonOptions"
                  :disabled="isLoading"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="form-buttons d-flex justify-content-end"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  :disabled="isLoading"
                  variant="primary"
                  @click="getEmails()"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  :disabled="isLoading"
                  variant="outline-secondary"
                  @click="resetFilters()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card>
          <b-row
            class="header-card"
            align-v="start"
          >
            <b-col cols="8">
              <h4>
                Emails Desativados
              </h4>
            </b-col>
            <b-col cols="4">
              <div class="d-flex justify-content-end">
                <b-button
                  :disabled="isLoading"
                  variant="secondary"
                  class="ml-auto"
                  @click="$root.$emit('bv::toggle::collapse', 'new-mail-disable')"
                >
                  Adicionar
                </b-button>
              </div>
              <b-collapse
                id="new-mail-disable"
              >
                <b-card
                  variant="dark"
                >
                  <validation-observer ref="rulesNewMailDisable">
                    <validation-provider
                      #default="{ errors }"
                      rules="required|email"
                    >
                      <b-form-group
                        label="Email"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="newMailDisable.email"
                          :disabled="isLoading"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>

                    <div class="d-flex justify-content-end">
                      <b-button
                        :disabled="isLoading"
                        variant="primary"
                        @click="handleNewMailDisable"
                      >
                        Salvar
                      </b-button>
                    </div>
                  </validation-observer>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>

          <div v-if="isSubmited">
            <b-row>
              <b-col cols="12">
                <b-table
                  :busy="isLoading"
                  responsive
                  :items="results"
                  :fields="result_fields"
                  show-empty
                  no-local-sorting
                >
                  <template #empty>
                    <h4 class="text-center mt-2 mb-2">
                      Nenhum resultado encontrado
                    </h4>
                  </template>

                  <template #table-busy>
                    <div class="text-center table-spinner">
                      <b-spinner label="Loading..." />
                      <strong>Carregando...</strong>
                    </div>
                  </template>

                  <template #head(email)>
                    <div class="text-nowrap">
                      Email
                    </div>
                  </template>

                  <template #head(created_at)>
                    <div class="text-nowrap">
                      Data de bloqueio
                    </div>
                  </template>

                  <template #head(disable_until)>
                    <div class="text-nowrap">
                      Data de liberação
                    </div>
                  </template>

                  <template #head(actions)>
                    <div class="text-nowrap text-center">
                      Ações
                    </div>
                  </template>

                  <template #cell(email)="row">
                    <div>
                      ({{ row.item.id }}) {{ row.item.email }}
                    </div>
                  </template>

                  <template #cell(created_at)="row">
                    <div class="text-nowrap">
                      {{ row.item.created_at ? formatDateDbToView(row.item.created_at) : '' }}
                    </div>
                  </template>

                  <template #cell(disable_until)="row">
                    <div class="text-nowrap">
                      {{ row.item.disable_until ? formatDateDbToView(row.item.disable_until) : '' }}
                    </div>
                  </template>

                  <template #cell(actions)="row">
                    <div class="text-nowrap text-center">
                      <b-button
                        variant="primary"
                        title="Ativar email"
                        :disabled="row.item.deleting"
                        @click="enableEmail(row.item)"
                      >
                        Ativar
                        <b-spinner
                          v-if="row.item.deleting"
                          small
                        />
                      </b-button>
                    </div>
                  </template>

                  <template #row-details="row">
                    <p>
                      {{ $t(`disabledEmail.reason.${row.item.reason}`) }}
                    </p>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row
              v-if="!isLoading"
            >
              <b-col cols="6">
                <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
              </b-col>

              <b-col cols="6">
                <b-pagination-nav
                  v-model="currentPage"
                  :number-of-pages="pages.last_page"
                  base-url="#"
                  align="end"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCol,
  BSpinner,
  BBreadcrumb,
  BCard,
  BTable,
  BPaginationNav,
  BCollapse,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import EmailService from '@/services/emailService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { formatDateDbToView } from '@/utils/helpers'
import { disabledEmailReasonOptions, withEmptyOptionFirst } from '@/utils/options'

export default {
  components: {
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCol,
    BSpinner,
    BBreadcrumb,
    BCard,
    BTable,
    BPaginationNav,
    BCollapse,
    BCardActions,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    const defaultFilterValues = {
      email: '',
      reason: '',
      createdAt: '',
      disableUntil: '',
    }
    return {
      disabledEmailReasonOptions: withEmptyOptionFirst(disabledEmailReasonOptions),
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'E-mails',
          active: true,
        },
        {
          text: 'Desativados',
          active: true,
        },
      ],
      pickrConfig: {
        mode: 'range',
        allowInput: false,
        position: 'auto center',
        dateFormat: 'Y-m-d',
        altFormat: 'd/m',
        altInput: true,
        locale: Portuguese,
      },
      isLoading: false,
      defaultFilterValues,
      filtersValues: {
        ...defaultFilterValues,
      },
      pages: {},
      currentPage: 1,
      results: [],
      isSubmited: false,
      formatDateDbToView,

      newMailDisable: {
        email: '',
      },
    }
  },
  computed: {
    result_fields() {
      return [
        { key: 'email', sortable: false },
        { key: 'created_at', sortable: false, thStyle: 'width: 120px' },
        { key: 'disable_until', sortable: false, thStyle: 'width: 120px' },
        { key: 'actions', sortable: false, thStyle: 'width: 120px' },
      ]
    },
  },
  watch: {
    currentPage() {
      this.getEmails()
    },
  },
  methods: {
    enableEmail(result) {
      this.results.some((s, index) => {
        if (s.id === result.id) {
          this.results.splice(index, 1, {
            ...result,
            deleting: true,
          })
          return true
        }

        return false
      })

      EmailService.enableDisabled(result.id).then(response => {
        if (response.data.status) {
          this.results.some((s, index) => {
            if (s.id === result.id) {
              this.results.splice(index, 1)
              return true
            }

            return false
          })

          this.toast('Sucesso!', 'Email ativado', true)
        } else {
          this.toast('Erro!', 'Não foi possível ativar o email', false)

          this.results.some((s, index) => {
            if (s.id === result.id) {
              this.results.splice(index, 1, {
                ...result,
                deleting: false,
              })
              return true
            }

            return false
          })
        }
      })
    },
    clearResults() {
      this.results = []
      this.isSubmited = false
      this.pages = {}
    },
    resetFilters() {
      this.filtersValues = {
        ...this.defaultFilterValues,
      }

      this.clearResults()
    },
    getEmails() {
      if (this.isLoading) {
        return false
      }

      this.isLoading = true
      this.isSubmited = true

      const data = { ...this.filtersValues }

      if (data.createdAt.includes(' até ')) {
        [data.createdAtFrom, data.createdAtTo] = data.createdAt.split(' até ')
      } else {
        data.createdAtFrom = data.createdAt
      }

      if (data.disableUntil.includes(' até ')) {
        [data.disableUntilFrom, data.disableUntilTo] = data.disableUntil.split(' até ')
      } else {
        data.disableUntilFrom = data.disableUntil
      }

      EmailService.listDisabledEmails(data, this.currentPage)
        .then(response => {
          if (response.data !== null) {
            this.results = response.data.data.email.data.map(e => ({ ...e, _showDetails: true }))

            if (this.results.length === 0) {
              this.toast('Erro!', 'Nenhum resultado encontrado com os termos pesquisados', false)
            }

            this.pages = {
              ...response.data.data.email,
              data: null,
            }
          }

          this.isLoading = false
        })

      return true
    },

    resetNewMailDisable() {
      this.newMailDisable = {
        email: '',
      }
    },

    toast(title, text, success) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: success ? 'EditIcon' : 'BellIcon',
          variant: success ? 'info' : 'danger',
        },
      }, {
        position: 'top-center',
      })
    },

    handleNewMailDisable() {
      this.$refs.rulesNewMailDisable.validate().then(success => {
        if (!success) {
          return
        }

        this.isLoading = true

        const { email } = this.newMailDisable

        EmailService.disable({ email }).then(response => {
          if (response.status === 200) {
            this.resetNewMailDisable()
            this.toast('Desabilitado!', 'Email desabilitado com sucesso', true)
          } else {
            this.toast('Erro!', 'Não foi possível desativar o email', false)
          }
        }).catch(error => {
          const errorMessage = error?.response?.data?.errors[1]

          if (errorMessage) {
            this.toast('Erro!', errorMessage, false)
          } else {
            this.toast('Erro!', 'Não foi possível desativar o email', false)
          }
        }).finally(() => {
          this.isLoading = false
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
